
import Vue from 'vue';
import { mapState } from 'vuex';
import { Helpers } from '@/utils';
import {
  BaseCard,
  BaseIcon,
} from '@/components/BaseComponents';

export default Vue.extend({
  name: 'ViewVideo',

  components: {
    BaseCard,
    BaseIcon,
  },

  computed: {
    ...mapState(['video']),
    videoState(): Services.Gallery.Response.Video | undefined {
      return (this.video as Store.Video.State).data;
    },
  },

  mounted() {
    if (!this.videoState) this.$router.back();
  },

  methods: {
    formatDate(date: string) {
      return Helpers.formatDate(this.$root.$i18n.locale as 'en-US' | 'pt-BR', date);
    },
    showNotification(text: string, type: Store.Notification.Type) {
      this.$store.dispatch('notification/create', { text, type });
    },
  },
});
